body{
  background-color: black;
}
.headerColor  {
   background: rgb(0, 0, 0)
}
 
.siteGradient{
  padding: 20vh;
  background: linear-gradient(
    rgba(255, 0, 0, 0) ,
    #bdbdbd
  );
}

.siteColor{
  background: 
  #bdbdbd
}

.parallax-Height {
  min-height: 10vh !important;
}

.serviceIcons {
  font-size: 50px;
  padding-right: 50px;
  width: 100px;
}

.serviceIconsMobile {
  padding-top: 10px;
  font-size: 50px;
  width: 100px;
}

.technologyIcons {
  font-size: 80px;
  padding-right: 50px;
  width: 100px;
}

.technologyIconsMobile {
  font-size: 60px;
  padding: 15px;
}

.sectionIcons {
  font-size: 90px;
  padding-bottom: 5px;

}
.contactIcon{
  font-size: 25px;
  padding-right: px;
  width: 50px;
}

.largePadding{
  padding-top: 13vh;
}

 #apppage .view {
   background-color: black;
   background-repeat: no-repeat;
   background-size: cover;
   background-position: center center;
   min-height: 100vh;
 }
 
 #apppage h6 {
   line-height: 1.7;
 }
 
 @media only screen and (max-width: 400px) {
   #apppage .animated img {
     max-height: 50vh;
     transform: translateX(-50%);
     left: 50%;
     position: absolute;
   }
 }
 @media only screen and (max-width: 767px) {
   #apppage .animated img {
     max-height: 35vh;
     transform: translateX(-50%);
     left: 50%;
   }
 }
 
 @media (max-width: 767px) and (orientation: landscape) {
   #apppage .animated img {
     max-height: 50vh;
     transform: translateX(-50%);
     left: 50%;
     position: relative;
   }
 }
 @media (max-width: 1000px) and (orientation: landscape) {
   #apppage .view {
     min-height: 150vh;
   }
 }
